<template>
  <div class="auth-wrapper auth-v1">
    <v-snackbar
      v-model="isSnackbarCenteredVisible"
      :light="$vuetify.theme.dark"
      centered
      color="error"
    >
      {{snackbarMessage}}
    </v-snackbar>
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold brand-name">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form" @submit.prevent="handleFormSubmit">
            <v-text-field
              v-model="firstName"
              :rules="[required]"
              outlined
              label="Имя"
              placeholder=""
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="lastName"
              :rules="[required]"
              outlined
              label="Фамилия"
              placeholder=""
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="[required,emailValidator]"
              outlined
              label="Почта"
              placeholder=""
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Пароль"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-checkbox
              hide-details
              class="mt-1"
              v-if="false"
            >
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">I agree to</span><a href="javascript:void(0)">privacy policy &amp; terms</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
            >
              Регистрация
            </v-btn>
          </v-form>
        </v-card-text>
                        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">Или</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- title -->
        <v-card-text class="text-center">
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Войти с помощью
          </p>
        </v-card-text>

        <v-card-text class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-5"
            @click.prevent="handleSignupWindow(link.name)"
          >
            <v-icon large :color="$vuetify.theme.dark ? link.colorInDark:link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            У вас уже есть аккаунт?
          </span>
          <router-link :to="{name:'auth-login'}">
            Войти
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiMicrosoft, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline, mdiApple } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import themeConfig from '@themeConfig'
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, signInWithRedirect, OAuthProvider, TwitterAuthProvider, getRedirectResult } from 'firebase/auth'
import { useRouter } from '@core/utils'
import store from '@/store'

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Register'
  },
  setup() {
    const isPasswordVisible = ref(false)
    const form = ref(null)
    const firstName = ref('')
    const lastName = ref('')
    const email = ref('')
    const password = ref('')
    const auth = getAuth();
    auth.languageCode = 'ru';
    const { router } = useRouter()
    const isSnackbarCenteredVisible = ref(false)
    const snackbarMessage = ref('There was an error')
    const provider = {
      google: new GoogleAuthProvider(),
      facebook: new FacebookAuthProvider(),
      microsoft:  new OAuthProvider('microsoft.com'),
      twitter: new TwitterAuthProvider(),
    }

    const socialLink = computed(() => {
      let socialLinkArr = [{
          icon: mdiFacebook,
          color: '#4267b2',
          colorInDark: '#4267b2',
          name: 'facebook'
        },
        {
          icon: mdiMicrosoft,
          color: '#737373',
          colorInDark: '#1da1f2',
          name: 'microsoft'
        },
        {
          icon: mdiTwitter,
          color: '#1da1f2',
          colorInDark: '#1da1f2',
          name: 'twitter'
        }]
        if( !window.navigator.userAgent.includes('Instagram') && !window.navigator.userAgent.includes('FBAV') && !window.navigator.userAgent.includes('FBAN')) {
          socialLinkArr.unshift({
            icon: mdiGoogle,
            color: '#db4437',
            colorInDark: '#db4437',
            name: 'google'
          })
        }
        return socialLinkArr;
    })

    const handleSignupWindow = (name) => {
      signInWithRedirect(auth, provider[name])
    }

    getRedirectResult(auth)
      .then((result) => {
        // The signed-in user info.
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        if(errorCode == 'auth/account-exists-with-different-credential') {
          snackbarMessage.value = 'Электронная почта зарегистрирована у другого провайдера. Пожалуйста, выберите другой';
          isSnackbarCenteredVisible.value = true;
        }
        // ...
      });
    
    const handleFormSubmit = () => {
      if( form.value.validate() ) {
        createUserWithEmailAndPassword(auth, email.value, password.value)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            user.displayName = firstName.value + ' ' + lastName.value;
            store.dispatch('user/createNewUser', user)
            .then(() => {
              store.dispatch('user/getCurrentUser', user)
              router.push({ name: 'home' })
            })
          })
          .catch((error) => {
            const errorMessage = error.message;
            let responseMessage;
            switch(errorMessage) {
              case 'Firebase: Error (auth/email-already-in-use).':
                responseMessage = 'Адрес электронной почты уже используется. Войти вместо этого'
                break;
              case 'Firebase: Password should be at least 6 characters (auth/weak-password).':
                responseMessage = 'Пароль должен быть не менее 6 символов'
                break;
              default:
                responseMessage = 'При входе произошла ошибка'
            }
            snackbarMessage.value = responseMessage;
            isSnackbarCenteredVisible.value = true;
            password.value = '';

            // ..
          });
      }
    }

    return {
      handleFormSubmit,
      handleSignupWindow,
      isPasswordVisible,
      form,
      firstName,
      lastName,
      email,
      password,
      socialLink,
      isSnackbarCenteredVisible,
      snackbarMessage,
      required,
      emailValidator,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
